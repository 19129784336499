import React from 'react';
import { Container } from 'reactstrap';
import Principal from './Principal';
import Orden from './Orden';

export default class Restaurante extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      mesa:{},
      ordenando:false,
      tipos:[],
      platillos:[]
    };
    this.cargarCatalogos();
  }
  cargarCatalogos = async() =>{
    this.setState({cargando:true});
    let url = `${this.props.urlws}/platillo/menu/${this.props.emp}`;
    let resp = await fetch(url);
    const platillos = await resp.json();
    url = `${this.props.urlws}/platillo/tipos/${this.props.emp}`;
    resp = await fetch(url);
    const tipos = await resp.json();
    this.setState({cargando:false, platillos,tipos });
  }
  ordenar = (m) =>{
    this.setState({mesa:m, ordenando:true});
  }
  principal = () =>{
    this.setState({ordenando:false});
  }
  render() {
    let {urlws} = this.props;
    let {emp} = this.props;
    return (
      <Container>
        {!this.state.ordenando ?
          <Principal urlws={urlws} emp={emp} ordenar={this.ordenar} />
        :
          <Orden urlws={urlws} emp={emp} platillos={this.state.platillos} tipos={this.state.tipos} mesa={this.state.mesa} regresar={this.principal}/>
        }
      </Container>
    );
  }
}
