import React from 'react';
import { Container, Row, Button } from 'reactstrap';

export default class MenuOpciones extends React.Component {
  render() {
    return (
      <Container>
          <Row>
            <Button size="sm" color="warning" onClick={() => this.props.seleccion('platillos')}>Platillos</Button>
            &nbsp;&nbsp;&nbsp;
            <Button  size="sm" color="warning"  onClick={() => this.props.seleccion('meseros')}>Meseros</Button>
            &nbsp;&nbsp;&nbsp;
            <Button  size="sm" color="warning" onClick={() => this.props.seleccion('restaurante')}>Restaurante</Button>
            &nbsp;&nbsp;&nbsp;
            <Button  size="sm" color="warning" onClick={() => this.props.seleccion('reportes')}>Reportes</Button>
          </Row>
      </Container>
    );
  }
}

