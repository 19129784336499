import React from 'react';
import {Container,Modal, ModalBody, Row, ModalHeader, Button, CardBody, Input , CardHeader} from 'reactstrap';
import Mesa from './Mesa';
import { Badge, Card} from 'reactstrap';

import Cuenta from './Cuenta';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { interfaceDeclaration } from '@babel/types';
import { rootCertificates } from 'tls';

export default class Menu extends React.Component{
    opcTxt = "";
    constructor(props){
        super(props);
        this.state = {
            tipo : 0,
            cant:1,
            texto:'',
            mostrarModal:false,
            platillo:{}
        }
    }
    componentWillReceiveProps(newProps){
        if(this.state.tipo === 0)
            this.setState({
                tipo:newProps.tipos[0].Id
            });
    }
    onChange = (e) => {
        const campo = e.target.name;
        const valor = e.target.value;
        this.setState({[campo]:valor});
    }
    handleClose = () =>{
        this.setState({mostrarModal:false});
    }
    capturar = (platillo) =>{
        this.opcTxt = "";
        this.setState({mostrarModal:true, platillo});
    }
    filtrar = (tipo) =>{
        this.setState({tipo});
    }
    agregar = () =>{
        let txt = this.state.texto;
        if(txt === "")
           txt = this.opcTxt;
        else
           txt = txt + ' ' + this.opcTxt;
        const pla = {
            id : this.state.platillo.Id,
            nom : this.state.platillo.Nombre,
            pre: this.state.platillo.Precio,
            cant : this.state.cant,
            texto :txt
        };
        this.props.agregarRenglon(pla);
        this.setState({mostrarModal:false, texto : ''});
    }
    opcionesTxt = (txt) =>{
        this.opcTxt = txt;
    }
    render() {
        const {tipo} = this.state;
        let opcs = [];
        if(this.state.platillo.Opciones !== undefined){
            const opciones = this.state.platillo.Opciones;
            if(opciones !== "")
                opcs = opciones.split(',');
        }
        return(
            <Container>
                <Row>
                    {this.props.tipos.map((m,i)=>
                    <div style={{padding:5}}>
                        <Badge onClick={() => this.filtrar(m.Id)} href="#" color="warning">{m.Nombre}</Badge>
                    </div>
                )}
                </Row>
                <Row>
                {this.props.platillos.filter((o) => o.IdTipo === tipo).map((m,i)=>
                    <div style={{padding:5}}>
                        <Platillo platillo={m} capturar={this.capturar}/>
                    </div>
                )}
                </Row>
                <Modal id="dlg" name="dlg" isOpen={this.state.mostrarModal} toggle={this.handleClose} tabIndex={1}>
                    <ModalHeader>
                    <span>{this.state.platillo.Nombre}</span>
                    </ModalHeader>
                    <ModalBody>
                        <Container>
                            <Row>
                                <div className="col-2">Cantidad</div>
                                <div className="col-2">
                                <Input size="sm" type="text" name="cant" value={this.state.cant} onChange={this.onChange} autoFocus />
                                </div>
                            </Row>
                            &nbsp;
                            <Row>
                                <div className="col-2">Indicaciones</div>
                                <div className="col-10">
                                <Input size="sm" type="text" name="texto" value={this.state.texto} onChange={this.onChange} />
                                </div>
                            </Row>
                            <Row>
                                <Opciones opciones={opcs} opcionesTxt={this.opcionesTxt}/>
                            </Row>
                            &nbsp;&nbsp;
                            <Row>
                                <Button size="sm" color="success" onClick={this.agregar}>Aceptar</Button>
                                &nbsp;&nbsp;
                                <Button size="sm" color="danger" onClick={() => this.setState({mostrarModal:false}) }>Cancelar</Button>
                            </Row>
                        </Container>
                    </ModalBody>
                </Modal>
            </Container>    
        )
    }
}

class Platillo extends React.Component{
    render(){
        return(
            <Card outline color="success" style={{width: 220}}>
                <CardHeader>
                    <h6>
                        Precio $<Badge href="#" color="warning">{this.props.platillo.Precio}</Badge>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <Button size="sm" color="success" onClick={() => this.props.capturar(this.props.platillo)}>+</Button>
                    </h6>
                </CardHeader>
                <CardBody>
                    <span>{this.props.platillo.Nombre}</span>
                </CardBody>       
            </Card>            
        )
    }
}
class Opciones extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            texto:'',
            opciones:this.props.opciones.map((o,i) =>  { return {texto:o, checked:true};})
        }
    }
    componentWillReceiveProps(newProps){
        this.setState({texto:'', opciones:newProps.opciones.map((o,i) => { return {texto:o, checked:true};})});
    }
    cambiar = (i,checked) =>{
        let texto = "";
        let sepa = "";
        this.state.opciones[i].checked = checked;
        for(let k = 0; k < this.state.opciones.length; k++)
            if(!this.state.opciones[k].checked){
                texto += sepa + "S/" + this.state.opciones[k].texto;
                sepa = " ,";
            }
        this.setState({texto});
        this.props.opcionesTxt(texto);
    }
    render() {
        return(
            <div>
                <span>{this.state.texto}</span><br/>
                {this.state.opciones.map((o,i) => 
                    <Opcion opcion={o} indice={i} cambiar={this.cambiar}/>
                )}
            </div>
        )
    }
}
class Opcion extends React.Component{
    onChecked = (e) =>{
        this.props.cambiar(this.props.indice, e.target.checked);
    }
    render(){
        return (
            <h6>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<Input type="checkbox" checked={this.props.opcion.checked} onChange={this.onChecked}/>{this.props.opcion.texto}</h6>
        )
    }
}