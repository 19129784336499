import React from 'react';
import {Container, Row, Card, Button, CardBody, Badge, Table , CardHeader} from 'reactstrap';
import Mesa from './Mesa';
import Menu from './Menu';
import Cuenta from './Cuenta';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { interfaceDeclaration } from '@babel/types';

export default class Orden extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            comanda:[]
        };
    }
    agregarRenglon = (ren) =>{
        let {comanda} = this.state;
        comanda.push({
            Id:ren.id,
            Nom:ren.nom,
            Cant:ren.cant,
            Pre:ren.pre,
            Imp:ren.cant * ren.pre,
            Txt:ren.texto
        });
        this.setState({comanda});
    }
    guardar = () =>{
        if(this.state.comanda.length === 0){
            this.props.regresar();
            return;
        }
        Swal.fire({
            title: '¿ Guardar la comanda ?',
            text: "Confirme",
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Si',
            cancelButtonText: 'No'
          }).then((result) => {
            if (result.value) {
                this.guardarB();
            }
          })        
    }
    guardarB = async () => {
        let total = 0;
        let det = [];
        let {comanda} = this.state;
        for(let k = 0; k < comanda.length; k++){
           total += comanda[k].Imp;
           det.push({
               Id:comanda[k].Id,
               Cant:comanda[k].Cant,
               Extras:comanda[k].Txt
           });
        }
        const mov = {
            Empresa : this.props.emp,
            Clave : "COM",
            Numero : 0,
            Mesero : this.props.mesa.IdMesero,
            Mesa : this.props.mesa.Id,
            Importe:total,
            S: JSON.stringify(det)
        }
        const url = this.props.urlws +"/comanda/wsgraba";
        this.setState({cargando:true});
        const json = JSON.stringify(mov);
        const resp = await fetch(url,{
            method: 'POST', // or 'PUT'
            body: json // data can be `string` or {object}!
          });
        const res = await resp.text();
        this.setState({cargando:false});
        if(res === "ok"){
            Swal.fire('Atencion','Datos guardados','success');
            this.setState({editando:false});
            this.props.regresar();
        }
        else
            Swal.fire('Atencion',res,'error');
    }
    cancelar = () =>{
        if(this.state.comanda.length === 0){
            this.props.regresar();
            return;
        }
        Swal.fire({
            title: '¿ Salir sin guardar ?',
            text: "Confirme",
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Si',
            cancelButtonText: 'No'
          }).then((result) => {
            if (result.value) {
                this.props.regresar();
            }
          })        
    }
    render(){
        return (
            <Container>
                <Row>
                    <div className="col-6">
                        <div style={{ height: 600, overflow: 'auto' }}>
                            <Menu tipos={this.props.tipos} 
                                platillos={this.props.platillos} 
                                urlws={this.props.urlws} 
                                emp={this.props.emp} 
                                mesa={this.props.mesa} 
                                agregarRenglon={this.agregarRenglon}/>
                        </div>
                    </div>
                    <div className="col-6">
                        <Comanda urlws={this.props.urlws} 
                            mesa={this.props.mesa} 
                            emp={this.props.emp} 
                            comanda={this.state.comanda}
                            guardar={this.guardar}
                            cancelar={this.cancelar}
                            />
                    </div>
                </Row>
            </Container>
        );
   }
}

class Comanda extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            total:0
        };
    }    
    componentWillReceiveProps(newProps){
        const cuenta = newProps.comanda;
        let total = 0;
        for(let k = 0; k < cuenta.length; k++)
           total += cuenta[k].Imp;
        this.setState({total});
    }
    render(){
        return(
            <Container>
                <div style={{padding:5}}>
                <Card outline color="warning" style={{height:600}}>
                        <CardHeader size="sm">
                            <h5>
                            <Badge href="#" color="secondary">Nueva comanda</Badge>
                            &nbsp;Mesa <Badge href="#" color="success">{this.props.mesa.Nombre}</Badge>
                            &nbsp;Mesero <Badge href="#" color="warning">{this.props.mesa.Mesero}</Badge>
                            </h5>
                        </CardHeader>
                        <CardBody>
                            <Row>
                                &nbsp;&nbsp;&nbsp;&nbsp;
                                <Button size="sm" color="success" onClick={this.props.guardar}>Aceptar</Button>
                                &nbsp;&nbsp;
                                &nbsp;&nbsp;
                                &nbsp;&nbsp;
                                <Button size="sm" color="danger" onClick={this.props.cancelar}>Cancelar</Button>
                            </Row>
                            <br/>
                            <Table size="sm" striped>
                                <thead>
                                    <tr>
                                        <th style={{width:40}}>Cant.</th>
                                        <th>Nombre</th>
                                        <th style={{width:60}} className="numero">Precio</th>
                                        <th style={{width:60}} className="numero">Importe</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.props.comanda.map((o,i) =>
                                    <React.Fragment>
                                        <tr>
                                            <td className="numero">{o.Cant}</td>
                                            { o.Txt ?
                                            <td>{o.Nom} <br/> -- {o.Txt}</td>
                                            :
                                            <td>{o.Nom}</td>
                                            }
                                            <td className="numero">{o.Pre}</td>
                                            <td className="numero">{o.Imp}</td>
                                        </tr>
                                    </React.Fragment>
                                    )}
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <td></td>
                                        <td>TOTAL</td>
                                        <td></td>
                                        <td className="numero" style={{fontSize:18, fontWeight:'bold'}}>{this.state.total}</td>
                                    </tr>
                                </tfoot>
                            </Table>
                        </CardBody>
                    </Card>
                </div>
            </Container>        
        );
    }
}