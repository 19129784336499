import React from 'react';
import { Input, Container, Card, Button, CardBody, Row , CardHeader, Table, Badge} from 'reactstrap';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { EILSEQ } from 'constants';

export default class Cuenta extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            cargando:false,
            mostrarExtras:true,
            mesa:this.props.mesa,
            total:0,
            cuenta:[]
        };
        this.cargarCuenta();
    }    
    componentWillReceiveProps(newProps){
        this.setState({mesa:newProps.mesa},() =>this.cargarCuenta())
    }
    onChecked = (e) =>{
        this.setState({mostrarExtras: e.target.checked});
    }
    cargarCuenta = async () => {
        if(this.props.agregando){
            this.setState({cuenta:this.props.comanda});
           return;
        }
        this.setState({cargando:true});
        let url = `${this.props.urlws}/mesa/cuenta/${this.state.mesa.Id}?emp=${this.props.emp}`;
        let resp = await fetch(url);
        let total = 0;
        const cuenta = await resp.json();
        for(let k = 0; k < cuenta.length; k++)
           total += cuenta[k].Imp;
        this.setState({cargando:false, cuenta, total });
    }
    cerrar = async () => {
        this.setState({cargando:true});
        let url = `${this.props.urlws}/mesa/cerrar/${this.state.mesa.Id}?emp=${this.props.emp}`;
        let resp = await fetch(url);
        const res = await resp.json();
        if(res[0].substring(0,5) !== "Error"){
            this.setState({cargando:false, cuenta:[]});
            this.props.cerrar();
            for(let k = 1; k < res.length; k++)
               this.imprimirTK(res[k]);
        }
        else{
            this.setState({cargando:false});
            Swal.fire('Atencion',res[0],'error'); 
        }
    }
    imprimirTK = (num) =>{
        let params = `?emp=${this.props.emp}&num=${num}`;
        let url = this.props.urlws + '/mesa/imprimirTiketPDF' + params;
        window.open(url, '', '');
    }
    imprimir = (mov) =>{
        let params = `/${this.state.mesa.Id}?emp=${this.props.emp}`;
        let url = this.props.urlws + '/mesa/imprimirCuentaPDF' + params;
        window.open(url, '', '');
    }
    cambiarCuenta = async(cta,id,i) => {
        const { value: letra } = await Swal.fire({
            title: 'Separar cuentas',
            input: 'select',
            inputValue:cta,
            inputOptions: {
              A: 'A',
              B: 'B',
              C: 'C',
              D: 'D',
              E: 'E',
              F: 'F',
              G: 'G'
            },
            inputPlaceholder: 'especifique la cuenta',
            showCancelButton: true,
          })
          
          if (letra) {
            this.cambiarCuentaB(id,letra,i);
          }        
    }
    cambiarCuentaB = async (id,cta,i) => {
        this.setState({cargando:true});
        let url = `${this.props.urlws}/comanda/cambiarCuenta/${id}-${this.props.emp}-${cta}`;
        let resp = await fetch(url);
        const res = await resp.text();
        if(res === "ok"){
            this.state.cuenta[i].Cta = cta;
            this.setState({cargando:false});
        }else
            Swal.fire('Atencion',res,'error'); 
    }
    render(){
        return(
            <Container>
                <div style={{padding:5}}>
                <Card outline color="warning" style={{height:600}}>
                        <CardHeader size="sm">
                            <h5>Mesa <Badge href="#" color="success">{this.props.mesa.Nombre}</Badge>
                            &nbsp;Mesero <Badge href="#" color="warning">{this.props.mesa.Mesero}</Badge>
                            &nbsp;Personas <Badge href="#" color="secondary">{this.props.mesa.Personas}</Badge></h5>
                        </CardHeader>
                        <CardBody>
                            <Row>
                                &nbsp;&nbsp;
                                <Button size="sm" color="success" onClick={this.imprimir}>Imprimir</Button>
                                &nbsp;&nbsp;
                                <Button size="sm" color="primary" onClick={this.props.ordenar}>Agregar</Button>
                                &nbsp;&nbsp;
                                <Button size="sm" color="danger" onClick={this.cerrar}>Cerrar</Button>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                <span>&nbsp;&nbsp;&nbsp;&nbsp;<Input type="checkbox" checked={this.state.mostrarExtras} onChange={this.onChecked} />Mostrar extras</span>
                            </Row>
                            <br/>
                            <Table size="sm" striped>
                                <thead>
                                    <tr>
                                        <th style={{width:40}}>Cant.</th>
                                        <th>Nombre</th>
                                        <th style={{width:60}} className="numero">Precio</th>
                                        <th style={{width:60}} className="numero">Importe</th>
                                        <th style={{width:40}}>Cta</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.cuenta.map((o,i) =>
                                        <tr>
                                            <td className="numero">{o.Cant}</td>
                                            { this.state.mostrarExtras && o.Ext ?
                                            <td>{o.Nom} <br/> -- {o.Ext}</td>
                                            :
                                            <td>{o.Nom}</td>
                                            }
                                            <td className="numero">{o.Pre}</td>
                                            <td className="numero">{o.Imp}</td>
                                            <td><Badge href="#" color="warning" onClick={()=> this.cambiarCuenta(o.Cta,o.Id,i)}>&nbsp;{o.Cta}&nbsp;</Badge></td>
                                        </tr>
                                    )}
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <td></td>
                                        <td>TOTAL</td>
                                        <td></td>
                                        <td className="numero" style={{fontSize:18, fontWeight:'bold'}}>{this.state.total}</td>
                                    </tr>
                                </tfoot>
                            </Table>
                        </CardBody>
                    </Card>
                </div>
            </Container>        
        );
    }
}