import React from 'react';
import { Badge, Card, Button, CardBody, CardText , CardHeader} from 'reactstrap';

export default class Mesa extends React.Component{
    onCuentaClick = () => {
        this.props.verCuenta(this.props.mesa);
    }
    onAbrirClick = () => {
        this.props.abrir(this.props.mesa);
    }
    render(){
        const mesa = this.props.mesa.Nombre;
        const abierta = this.props.mesa.Abierta;
        const hora = this.props.mesa.FechaApertura;
        const personas = this.props.mesa.Personas;
        return(
            <Card outline color={abierta ? "success": "danger"} style={{width: 220}}>
                <CardHeader>
                    <h6>
                         <Badge href="#" color="primary">Mesa {mesa}</Badge>
                        &nbsp;&nbsp;
                        {abierta ?
                            <React.Fragment>
                                <Badge href="#" color="success">{hora}</Badge>
                                &nbsp;&nbsp;
                                <Badge href="#" color="secondary">{personas}</Badge>
                            </React.Fragment>
                        :
                        null
                        }
                    </h6>
                </CardHeader>
                <CardBody>
                {abierta?
                    <div>
                        <Button size="sm" color="warning" onClick={this.onCuentaClick}>Cuenta</Button>
                    </div>
                :<div>
                    <Button size="sm" color="success" onClick={this.onAbrirClick}>Abrir</Button>
                </div>
                }
                </CardBody>       
            </Card>            
        );
    }
}