import React from 'react';
import {Container, Row, Input, Button, CardBody, CardText , CardHeader} from 'reactstrap';
import Mesa from './Mesa';
import Cuenta from './Cuenta';
import Swal from 'sweetalert2'

export default class Principal extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            cargando:false,
            agregando:false,
            soloAbiertas:false,
            mesero:1,
            mesa : {}
        };
    }
    onChecked = (e) =>{
        this.setState({soloAbiertas: e.target.checked});
    }
    verCuenta = (m) =>{
        this.setState({mesa : m});
    }
    ordenar = () =>{
        this.props.ordenar(this.state.mesa);
    }
    cerrar = () =>{
        this.setState({cargando:false});
    }
    abrir = async(m) =>{
        const { value: personas } = await Swal.fire({
            title: 'Numero de personas',
            input: 'text',
            inputValue: '2',
            showCancelButton: true,
            inputValidator: (value) => {
              if (!value) {
                return 'ingresa el numero de personas'
              }
            }
          })
        if(personas){
            const cant = parseInt(personas);
            this.setState({cargando:true});
            let url = `${this.props.urlws}/mesa/abrir/${m.Id}?mesero=${this.state.mesero}&personas=${cant}`;
            let resp = await fetch(url);
            const res = await resp.text();
            this.setState({cargando:true});
            if(res !== "ok"){
                Swal.fire('Atencion',res,'error'); 
            }
        }
    }
    render(){
        return (
            <Container>
                <Row>
                    <div className="col-6">
                        <span>&nbsp;&nbsp;&nbsp;&nbsp;<Input type="checkbox" checked={this.state.soloAbiertas} onChange={this.onChecked} />Mostrar solo mesas abiertas</span><br/>
                        <div style={{ height: 600, overflow: 'auto' }}>
                            <Mesas urlws={this.props.urlws} verCuenta={this.verCuenta} emp={this.props.emp} abrir={this.abrir} soloAbiertas={this.state.soloAbiertas}/>
                        </div>
                    </div>
                    <div className="col-6">
                        <Cuenta urlws={this.props.urlws} mesa={this.state.mesa} emp={this.props.emp} ordenar={this.ordenar} cerrar={this.cerrar}/>
                    </div>
                </Row>
            </Container>
        );
   }
}

class Mesas extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            mesas:[]
        };
        this.cargarMesas();
    }
    componentWillReceiveProps(newProps){
        this.cargarMesas();
    }
    cargarMesas = async () => {
        this.setState({cargando:true});
        let url = `${this.props.urlws}/mesa/activas/${this.props.emp}`;
        let resp = await fetch(url);
        const mesas = await resp.json();
        this.setState({cargando:false, mesas });
        setTimeout(() => { this.cargarMesas(); }, 15000);
    }
    render(){
        let {mesas} = this.state;
        if(this.props.soloAbiertas)
           mesas = mesas.filter((m) => m.Abierta);
        return(
            <Container>
                <Row>
                {mesas.map((m,i)=>
                    <div style={{padding:5}}>
                        <Mesa mesa={m} verCuenta={this.props.verCuenta} abrir={this.props.abrir}/>
                    </div>
                )}
                </Row>
            </Container>    
        );
    }
}