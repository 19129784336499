import React from 'react';
import { Container, Col } from 'reactstrap';
import MenuOpciones from './MenuOpciones';
import Restaurante from './componentes/Restaurante';
import Platillos from './componentes/Platillos';
import Reportes from './componentes/Reportes';
import './App.css';

export default class App extends React.Component {
  constructor(props){
    super(props);
    let urlws = ''; //'https://www.rb.isaerpweb.com';
    //urlws = 'http://localhost:17988/';

    this.state = {
      empresa:2,
      urlws:urlws,
      opc:'restaurante'
    };
  }
  opcion = (opc) => {
    this.setState({opc});
  }
  render() {
    let {urlws} = this.state;
    let emp = this.state.empresa;
    let {opc} = this.state;
    return (
      <Container>
          <Col>
            <MenuOpciones seleccion={this.opcion} />
            { opc === 'restaurante' ?<Restaurante urlws={urlws} emp={emp} />:null}
            { opc === 'platillos' ?<Platillos urlws={urlws} emp={emp} />:null}
            { opc === 'reportes' ?<Reportes urlws={urlws} emp={emp} />:null}
          </Col>
      </Container>
    );
  }
}

