import React from 'react';
import { Container, FormGroup,Input, Button } from 'reactstrap';

export default class Reportes extends React.Component {
    constructor(props){
        super(props);
        let fec = new Date();
        fec.addDays(-7);
        let fecIni = fec.yyyymmdd('-');
        let fecFin = new Date().yyyymmdd('-');
        this.state = {
            cargando:false,
            fechaIni:fecIni,
            fechaFin:fecFin,
            reporte:-1
        };
    }
    onChange = (e) => {
        const campo = e.target.name;
        const valor = e.target.value;
        this.setState({ [campo] : valor});
    }
    imprimir = () =>{
        let {reporte} = this.state;
        let repo = '';
        if(reporte == 0)
           repo = 'cortePDF'
        else if(reporte == 1)
           repo = 'vtasPlatPDF'
        else if(reporte == 2)
           repo = 'comandasPDF'
        else if(reporte == 3)
           repo = 'comandasDetPDF'
        if(repo == '')
            return;
        let params = `${repo}?emp=${this.props.emp}&fecIni=${this.state.fechaIni}&fecFin=${this.state.fechaFin}`;
        let url = this.props.urlws + '/reporte/' + params;
        window.open(url, '', '');
    }
    render() {
        const reportes = ['Corte diario','Ventas por platillo','Listado de comandas','Listado de detalle de comandas'];
        return (
        <Container style={{padding:40}}>
            <div className="row">
            <div className="col-2">
                    <FormGroup>
                        <span>Del dia</span>
                        <Input size="sm" type="date" name="fechaIni" value={this.state.fechaIni} onChange={this.onChange} />
                    </FormGroup>
                </div>
                <div className="col-2">
                    <FormGroup>
                        <span>al dia</span>
                        <Input size="sm" type="date" name="fechaFin" value={this.state.fechaFin} onChange={this.onChange} />
                    </FormGroup>
                </div>
            </div>
            <div className="row">
                <div className="col-4">
                    <FormGroup>
                        <span>Reporte</span><br />
                        <Input size="sm" type="select" name="reporte" value={this.state.reporte} onChange={this.onChange} style={{ fontSize: '12px' }}>
                            <option key="0" value={-1}>Especifique</option>
                            {reportes.map((i, k) =>
                                <option key={k} value={k}>{i}</option>
                            )}
                        </Input>
                    </FormGroup>
                </div>
            </div>
            <div className="row">
                <div className="col-1">

                </div>
                <div className="col-3">
                    <Button size="sm" color="success" onClick={this.imprimir}>Imprimir</Button>
                </div>
            </div>
        </Container>
        );
    }
}

